<template>
    <div class="overflow-hidden">
        <div class="fixed  top-0 h-screen w-screen bg-gray-500 z-50 opacity-40">
        </div>

        <div class="flex absolute top-0 justify-center w-full items-center h-screen">
            <div class="relative rounded-lg bg-white z-50 w-full max-w-xl p-6">
                <form @submit.prevent="registerUser">
                    <div class="text-left">
                        <div class="">
                            <h3 class="text-base font-semibold leading-6 text-gray-900 mb-4">Register</h3>
                        </div>
                        <div class="my-2">
                            <hr>
                        </div>
                        <a @click="backToLogin"
                            class="flex w-fit items-center my-2 text-gray-600 hover:text-gray-800 cursor-pointer">
                            <ChevronLeftIcon class="h-4 w-4" />
                            Back to login
                        </a>
                        <div v-if="store.visibleActionMessage" :class="store.actionMessage.type"
                            class="p-2 w-[80%] mx-auto h-10 rounded-md accoutMessageAnimation text-center">
                            <p class="">{{ store.actionMessage.message }}</p>
                        </div>
                        <label for="name" class="block text-sm font-medium leading-6 text-gray-900">Nickname</label>
                        <div class="mt-2">
                            <input type="text" name="name" id="name" v-model="name" required autocomplete="off"
                                class="block p-4 w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                placeholder="e.g. John" />
                        </div>

                        <label for="email" class="block text-sm font-medium leading-6 text-gray-900">Email</label>
                        <div class="mt-2">
                            <input type="email" name="email" id="email" v-model="email" required autocomplete="off"
                                class="block p-4 w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                placeholder="e.g. John@gmail.com" />
                        </div>

                        <label for="password" class="block text-sm font-medium leading-6 text-gray-900">Password</label>
                        <div class="mt-2">
                            <input type="password" name="password" id="password" v-model="password" required min="8"
                                class="block p-4 w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                placeholder="password" />
                        </div>
                        
                        <div class="md:flex items-center">
                            <div class="mt-2.5">
                                <label for="gender" class="block text-sm font-medium leading-6 text-gray-900">Gender</label>
                                <select id="gender" name="gender" v-model="gender" required
                                    class="block w-full rounded-md border-0 py-2.5 px-2 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6">
                                    <option value="" selected disabled>Select your gender</option>
                                    <option value="male">Male </option>
                                    <option value="female">Female</option>
                                    <option value="others">Others</option>
                                </select>
                            </div>
                        </div>
                        
                        <div class="items-center">
                            <div class="mt-2.5">
                                <label for="date" class="block text-sm font-medium leading-6 text-gray-900">Date of Birth</label>
                            </div>
                            <div class="flex gap-2">
                                <div>
                                    <label for="dobMonth" class="block text-sm font-medium leading-6 text-gray-900">Month</label>
                                    <select id="dobMonth" v-model="dobMonth" required class="block w-full rounded-md border-0 py-2.5 px-2 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6">
                                        <option value="" disabled>Select month</option>
                                        <option v-for="month in months" :key="month.value" :value="month.value">{{ month.text }}</option>
                                    </select>
                                </div>
                                <div>
                                    <label for="dobDay" class="block text-sm font-medium leading-6 text-gray-900">Day</label>
                                    <select v-model="dobDay" class="block w-full rounded-md border-0 py-2.5 px-2 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6">
                                        <option value="" disabled>Select day</option>
                                        <option v-for="day in 31" :key="day" :value="`${day}`.padStart(2, '0')">{{ day }}</option>
                                    </select>
                                    <!-- <input type="number" id="dobDay" v-model="dobDay" required placeholder="Day" min="1" max="31"/> -->
                                </div>
                                <div>
                                    <label for="dobYear" class="block text-sm font-medium leading-6 text-gray-900">Year</label>
                                    <select v-model="dobYear" class="block w-full rounded-md border-0 py-2.5 px-2 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6">
                                        <option value="" disabled>Select year</option>
                                        <option v-for="year in years" :key="year" :value="year">{{ year }}</option>
                                    </select>
                                </div>
                            </div>
                        </div>

                        <div class="flex items-center mt-2.5">
                            <input type="checkbox" name="agreeTerms" :checked="agreeTerms" class="accent-red-600" 
                                @click="toggleAcceptTerms()">
                            <label for="agreeTerms" class="ml-1">I accept <a href="https://www.123freechat.com/legal/terms/" class="text-main">Terms of use</a></label>
                        </div>

                        <div>
                            <!-- <label for="country"
                                class="block text-sm font-medium leading-6 text-gray-900 mt-2">Country</label>
                            <input type="text" name="country" id="country" v-model="country" required placeholder="Enter your country"
                                class="block p-4 w-[20rem] rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" /> -->
                        </div>
                        <div class="w-full text-right">
                            <button type="submit" :disabled="store.isButtonDisable"
                                class="mt-4 rounded-md bg-red-600 disabled:bg-red-300 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600">Register</button>
                        </div>

                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<script setup>
import { ChevronLeftIcon } from '@heroicons/vue/24/solid';
import { ref, computed } from 'vue';
import axios from 'axios';
import { useMainStore } from "@/mainStore";
import { login } from '@/irc/client';
import { months } from '@/constants';

const store = useMainStore();

const backToLogin = () => {
    store.hideRegisterModal();
    store.showLoginModal();
}

const registerUser = () => {
    const registrationData = {
        name: name.value,
        email: email.value,
        password: password.value,
        dob: combinedDOB.value,
        // country: country.value,
        gender: gender.value,
        type: 'user',
    };
    
    if (!agreeTerms.value) {
        const message = {
            message: "You haven't agreed terms of use",
            type: 'warning'
        };
        store.storeActionMessage(message);
        store.showActionMessage();
        setTimeout(() => {
            store.hideActionMessage();
        }, 3000);
        return;
    }
    
    store.disableButton()
    let today = new Date();
    const age = today.getFullYear() - new Date(registrationData.dob).getFullYear();
    if (age >= 18 & age <= 90) {
        axios.post(`${store.config.apiHost}/register`, registrationData)
            .then(response => {
                store.disableButton
                // response.data.store()
                store.storeActionMessage(response.data);
                store.showActionMessage();
                setTimeout(() => {
                    store.hideActionMessage();
                }, 3000);

                // Handle registration success here
                setTimeout(() => {
                    login(name.value, registrationData.password);

                    const message = {
                        message: 'Connecting to server',
                        type: 'success'
                    };
                    setTimeout(() => {
                        store.hideActionMessage();
                    }, 3000);
                    store.socket.emit('login', { user: name.value, gender: gender.value, dob: combinedDOB.value, type: 'user' });
                    store.storeUserName(name.value);
                    store.storeGender(gender.value);
                    store.storeActionMessage(message);
                    store.showActionMessage();
                }, 1000);
                store.socket.on('userList', (userList) => {
                    userList.forEach(user => {
                        store.setSocketUserList(user);

                    });
                });
                store.socket.on('sentMessageHistory', (messages) => {
                    messages.forEach(message => {
                        store.storeSocketMessage(message);

                    });
                });
            })
            .catch(error => {
                store.enableButton()
                if (error.response && error.response.data) {
                    store.enableButton()
                    store.storeActionMessage(error.response.data);
                    store.showActionMessage();
                    setTimeout(() => {
                        store.hideActionMessage();
                    }, 3000);
                } else if (error.response) {
                    store.enableButton()
                } else {
                    store.enableButton()
                    const errorMessage = {
                        message: 'An error occurred during registration',
                        type: 'error',
                    };
                    store.storeActionMessage(errorMessage);
                    store.showActionMessage();
                    setTimeout(() => {
                        store.hideActionMessage();
                    }, 3000);
                }
            });
    } else {
        store.enableButton()
        if (age < 18) {
            const message = {
                message: 'You are not 18+',
                type: 'warning'
            };
            store.storeActionMessage(message);
            store.showActionMessage();
            setTimeout(() => {
                store.hideActionMessage();
            }, 3000);
        } else {
            const message = {
                message: 'You are 90+',
                type: 'warning'
            };
            store.storeActionMessage(message);
            store.showActionMessage();
            setTimeout(() => {
                store.hideActionMessage();
            }, 3000);
        }
    }

}

const parseDOB = (dob) => {
  const dobParts = dob?.split(/[*\-/]/);
  const isValidMonth = dobParts && dobParts[0] && parseInt(dobParts[0], 10) >= 1 && parseInt(dobParts[0], 10) <= 12;
  const isValidDay = dobParts && dobParts[1] && parseInt(dobParts[1], 10) >= 1 && parseInt(dobParts[1], 10) <= 31;
  const isValidYear = dobParts && dobParts[2] && parseInt(dobParts[2], 10) >= startYear && parseInt(dobParts[2], 10) <= endYear;

  return [
    isValidMonth ? dobParts[0] : "",
    isValidDay ? dobParts[1] : "",
    isValidYear ? dobParts[2] : ""
  ];
};

const params = new URLSearchParams(window.location.search);

const name = ref(params.get('name') || "");
const email = ref(params.get('email') || "");
const password = ref("");
const gender = ref(params.get('gender') || "");
const agreeTerms = ref(false)

// Fields for dob.
const dobStr = parseDOB(params.get('dob'));
const [dobMonth, dobDay, dobYear] = dobStr.map(part => ref(part));

const startYear = 1924;
const endYear = 2024
const years = Array.from({ length: endYear - startYear + 1 }, (_, i) => startYear + i);

const combinedDOB = computed(() => {
  if (dobMonth.value && dobDay.value && dobYear.value) {
    return `${dobYear.value}-${dobMonth.value.toString().padStart(2, '0')}-${dobDay.value.toString().padStart(2, '0')}`;
  }
  return '';
});

const toggleAcceptTerms = () => {
    agreeTerms.value = !agreeTerms.value
}

</script>