const months = [
  { text: 'January', value: '01' },
  { text: 'February', value: '02' },
  { text: 'March', value: '03' },
  { text: 'April', value: '04' },
  { text: 'May', value: '05' },
  { text: 'June', value: '06' },
  { text: 'July', value: '07' },
  { text: 'August', value: '08' },
  { text: 'September', value: '09' },
  { text: 'October', value: '10' },
  { text: 'November', value: '11' },
  { text: 'December', value: '12' },
];

const MSG_TYPE = Object.freeze({
    MESSAGE:   Symbol("MESSAGE"),
    GIF: Symbol("GIF"),
    ACTION: Symbol("ACTION"),
    WHISPER: Symbol("WHISPER"),
    JOIN: Symbol("JOIN"),
    QUIT: Symbol("QUIT"),
    PART: Symbol("PART")
});

const genderColors = {
  'male': '#1299ff',
  'female': '#ff69b4',
  'other': 'purple' 
}

const commands = [
  {
    command: '/me',
    format: "/me",
    description: 'Action',
    unit: 1,
    type: MSG_TYPE.ACTION
  },
  {
    command: '/join',
    alias: '/j',
    format: "/join #channel",
    description: 'Join a room',
    unit: 2,
    type: MSG_TYPE.JOIN
  },
  {
    command: '/part',
    alias: '/p',
    format: "/part #channel",
    description: 'Leave a room',
    unit: 2,
    type: MSG_TYPE.PART
  },
  {
    command: '/quit',
    alias: '/q',
    format: "/quit",
    description: 'Quit',
    unit: 1,
    type: MSG_TYPE.QUIT
  },
  {
    command: '/whisper',
    alias: '/w',
    format: "/whisper {user}",
    description: 'Whisper',
    unit: 2,
    type: MSG_TYPE.WHISPER
  },
]

export { months, genderColors, commands, MSG_TYPE }